import React, { Component } from 'react'
import styles from './styles.module.scss'
import { Button, Container, ImgBundle } from '~views/shared/components'
import { tx } from '~libs/i18n'
import { Link, graphql } from 'gatsby'
import { Header, Footer } from '~views'
import { withStore } from '~libs/mobx'
import { IImageBundle } from '~models'

interface AppProps {
  data: {
    wordpressAcfOptions: {
      site: {
        facebook: string
        instagram: string
        twitter: string
        image_404: IImageBundle
      }
      navigation: {
        headerlinks: {
          title: string
          link: {
            post_name: string
          }
        }[]
        footerlinks: {
          heading: string
          links: {
            title: string
            link: {
              post_name: string
            }
          }[]
        }[]
      }
    }
  }
}

class App extends Component<AppProps> {
  render() {
    const { wordpressAcfOptions } = this.props.data
    return (
      <>
        <Header
          links={wordpressAcfOptions.navigation.headerlinks}
          className={styles.header}
        />
        <div className={styles.wrapper}>
          <div className={styles.containerWrapper}>
            <Container>
              <p className={styles.strapline}>404</p>
              <h1 className={styles.title}>{tx('404.title')}</h1>

              <Link to={'/'} className={styles.button}>
                <Button>{tx('misc.back')}</Button>
              </Link>
            </Container>
          </div>

          <ImgBundle
            images={wordpressAcfOptions.site.image_404}
            className={styles.backgroundImage}
          />
        </div>
        <Footer
          options={wordpressAcfOptions.site}
          links={wordpressAcfOptions.navigation.footerlinks}
        />
      </>
    )
  }
}

export default withStore(App)

export const pageQuery = graphql`
  {
    wordpressAcfOptions {
      site {
        facebook
        instagram
        twitter
        image_404 {
          image_sm {
            ...imageFields
          }
          image_md {
            ...imageFields
          }
          image_lg {
            ...imageFields
          }
          image_xl {
            ...imageFields
          }
        }
      }
      navigation {
        headerlinks {
          title
          link {
            post_name
          }
        }
        footerlinks {
          heading
          links {
            title
            link {
              post_name
            }
          }
        }
      }
    }
  }
`
